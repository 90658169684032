import {
  Alert,
  Col,
  Modal,
  Row,
  Spin,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { Prompt } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { pick, get } from 'lodash';

import ViewingPermsCard from './ViewingPermsCard';
import InfoAndSetPermissionsCard from './InfoAndSetPermissionsCard';
import CreatorPermsCard from './CreatorPermsCard';

import paramsURL from '../utils/ParamsURL';
import { PermissionsContext } from './context';
import { capitalizeFirstChar } from '../utils';
import {
  createEntityPermissions, getActorPermissionsTree, getEntityPermission, getMyInfoAndListOfActors,
} from './actions/actionsCreator';
import { getMeIsAdmin } from '../profile/selectors';
import { clearActorsFetching, clearActorsWithBanedUsers, clearEntityPermissions } from './actions/actions';
import { fetchingGroupInActors, getListOfActorsFetchingFromPerm } from './selectors/selectors';

const defaultPerms = {
  create: false,
  read: false,
  update: false,
  delete: false,
  list: false,
  set: false,
};

const defaultNotSaved = { creatorCard: false, infoCard: false, expandCard: false };

function PermissionsStartPage({
  entityUUID,
  nameSectionPermissions,
  partitionType = 'PM',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dataFromUrl = useSelector((state) => paramsURL?.get(state));
  const checkAdmin = useSelector(getMeIsAdmin);
  const fetchingDefaultActorsList = useSelector(getListOfActorsFetchingFromPerm);
  const fetchingActorsByName = useSelector(fetchingGroupInActors);

  const [notSavedPermissions, setNotSavedPermissions] = useState(defaultNotSaved);
  const [resetFromMainContainer, setResetFromMainContainer] = useState(false);
  const { activeActorPerm } = dataFromUrl;

  const checkFetching = checkAdmin
    ? (!fetchingDefaultActorsList)
    : (!fetchingDefaultActorsList && !fetchingActorsByName);

  const confirmNotSaved = () => {
    Modal.confirm({
      title: capitalizeFirstChar(t('wms.labels.data_not_saved', 'your changes are not saved')),
      width: '600px',
      content: capitalizeFirstChar(t('wms.modals.body.changes_not_saved', 'your changes are not saved. Cancel or save your changes...')),
      okText: capitalizeFirstChar(t('wms.labels.reset_all_your_changes', 'your changes are not saved')),
      cancelText: capitalizeFirstChar(t('wms.buttons.close', 'close')),
      open: true,
      centered: true,
      maskClosable: true,
      onCancel() {
      },
      onOk() {
        setResetFromMainContainer(true);
      },
    });
  };

  const getName = (row) => {
    switch (get(row, 'actor_type')) {
      case 'group':
        return get(row, 'uinfo.group_name', 'Name no found');
      case 'service':
        return get(row, 'uinfo.service_name', 'Name no found');
      default:
        return `${get(row, 'uinfo.first_name', 'Name')}
     ${get(row, 'uinfo.last_name', 'not found')}`;
    }
  };

  const checkIsUserType = (type) => type === 'user'
    || type === 'classic_user'
    || type === 'phantom';

  const setNotSavedDataCallback = (type, bool) => {
    if (notSavedPermissions[type] !== bool) {
      setNotSavedPermissions((state) => ({
        ...state,
        [type]: bool,
      }));
    }
  };

  const getActorTreePermissions = (uuid) => {
    const data = {
      entity_uuid: entityUUID,
      actor: uuid,
    };

    return dispatch(getActorPermissionsTree({
      data,
      partition: partitionType,
    }));
  };

  const updateSwitchDataCallback = (currentItem, itemsWithNewPermForSend, newPermData) => {
    let checkActor = false;

    const newPerms = [...itemsWithNewPermForSend].map((i) => {
      if (get(i, 'actor_uuid') === get(currentItem, 'actor_uuid')
      && get(i, 'entity_uuid') === get(currentItem, 'entity_uuid')) {
        checkActor = true;
        return ({
          ...i,
          ...newPermData,
        });
      }
      return i;
    });

    if (!checkActor) {
      newPerms.push({
        ...currentItem,
        ...newPermData,
      });
    }

    return [...newPerms];
  };

  const updatePermissions = (params) => dispatch(createEntityPermissions({
    data: params,
    partition: partitionType,
  }));

  const viewingAlert = (type, message, description) => (
    <Alert
      message={message}
      description={description}
      type={type}
      className="mt-2"
      showIcon
      icon={(
        <Icon
          className="mr-3"
          path={mdiAlertCircleOutline}
          size={3}
        />
   )}
    />
  );

  const getEntityPerms = (uuid) => {
    const config = {
      partition: partitionType,
      params: {
        entity_uuid: uuid || entityUUID,
      },
    };
    return new Promise((resolve) => dispatch(
      getEntityPermission(config, (res) => { resolve(res); }),
    ));
  };

  const saveTreePermissions = async (data) => {
    // console.log('newPermsnewPermsnewPerms', data, entityUUID);
    const initialDataReducer = {
      currentEntity: { entity_uuid: '', data: [] },
      otherEntity: { entity_uuid: '', data: [] },
    };

    const filteredData = data.reduce((acc, currentItem) => {
      const updateItem = (entityName) => ({
        ...acc,
        [entityName]: {
          entity_uuid: get(currentItem, 'entity_uuid') || entityUUID,
          data: [
            ...acc[entityName].data,
            {
              actor: currentItem.actor_uuid,
              ...pick(currentItem, [
                'create',
                'read',
                'update',
                'delete',
                'list',
                'set',
              ]),
            },
          ],
        },
      });

      if (get(currentItem, 'entity_uuid') === entityUUID) {
        return updateItem('currentEntity');
      }
      return updateItem('otherEntity');
    }, initialDataReducer);

    if (filteredData.currentEntity.data.length !== 0) {
      await updatePermissions(filteredData.currentEntity);
    }

    if (filteredData.otherEntity.data.length !== 0) {
      await updatePermissions(filteredData.otherEntity);
    }

    await getEntityPerms();
  };

  const checkNotSavedPermissions = (currentCard) => {
    const name = ['entityCard', 'infoCard'].find((item) => item !== currentCard);
    if (notSavedPermissions[name]) {
      confirmNotSaved();
      return true;
    }
    return false;
  };

  const initFunc = async () => {
    const noGetBanedUsers = false;
    dispatch(clearActorsFetching);
    await getEntityPerms(entityUUID);
    dispatch(getMyInfoAndListOfActors(noGetBanedUsers));
  };

  useEffect(() => {
    initFunc();
    return () => {
      dispatch(clearEntityPermissions);
      dispatch(clearActorsFetching);
      dispatch(clearActorsWithBanedUsers);
      paramsURL?.clearKey(['activeActorPerm', 'typeActor'])
    };
  }, []);

  useEffect(() => {
    if (resetFromMainContainer) {
      setResetFromMainContainer(false);
    }
  }, [resetFromMainContainer]);

  // console.log('notSavedPermissions',notSavedPermissions)
  return (
    <PermissionsContext.Provider
      value={{
        entityUUID,
        partitionType,
        resetFromMainContainer,
        defaultPerms,
        getName,
        notSavedDataCallback: setNotSavedDataCallback,
        updateSwitchDataCallback,
        checkNotSavedPermissions,
        getEntityPerms,
        getActorTreePermissions,
        saveTreePermissions,
        checkIsUserType,
        viewingAlert,
      }}
    >

      <Spin size="small" spinning={!checkFetching}>
        <Row wrap={false}>
          <Col span={activeActorPerm ? 11 : 24}>
            <CreatorPermsCard
              nameSectionPermissions={nameSectionPermissions}
              notSavedPermissions={notSavedPermissions}
            />
            <ViewingPermsCard />

          </Col>

          <Col span={13} className={!activeActorPerm && 'd-none'}>
            <InfoAndSetPermissionsCard
              notSavedPermissions={notSavedPermissions}
            />
          </Col>

        </Row>
        {/*<Prompt*/}
        {/*  when={notSavedPermissions.creatorCard*/}
        {/*  || notSavedPermissions.infoCard*/}
        {/*  || notSavedPermissions.expandCard}*/}
        {/*  message={() => {*/}
        {/*    confirmNotSaved();*/}
        {/*    return false;*/}
        {/*  }}*/}
        {/*/>*/}
      </Spin>
    </PermissionsContext.Provider>

  );
}

PermissionsStartPage.propTypes = {
  nameSectionPermissions: PropTypes.string,
  partitionType: PropTypes.string,
  entityUUID: PropTypes.string,
};

export default PermissionsStartPage;
