import React from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  mdiAccountCircleOutline,
  mdiFileTreeOutline, mdiMonitorDashboard,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstChar } from '../utils';
import {
  getMeIsAdmin,
  getMeIsRoot,
} from '../profile/selectors';
import AuthHelper from '../auth/utils';
import VersionLabel from './VersionLabel';
import useURLParams from '../hooks/useURLParams';

function SidebarComponent() {
  const { t } = useTranslation();

  const { currentLocationPathname } = useURLParams();

  const meIsAdmin = useSelector(getMeIsAdmin);
  const meIsRoot = useSelector(getMeIsRoot);

  const menuItems = [
    {
      label: capitalizeFirstChar(t('ew.pages.profile', 'profile')),
      linkTo: '/profile',
      iconPath: mdiAccountCircleOutline,
      onlyAdmin: false,
    },
    {
      label: capitalizeFirstChar(t('ew.pages.entity_tree', 'entity tree')),
      linkTo: '/entity_tree',
      iconPath: mdiFileTreeOutline,
      onlyAdmin: false,
    },
    {
      label: capitalizeFirstChar(t('ew.pages.admin_dashboard', 'admin dashboard')),
      linkTo: '/admindashboard',
      iconPath: mdiMonitorDashboard,
      onlyAdmin: true,
    },
  ];

  return (
    <aside id="main-sidebar" className="dt-sidebar">
      <div className="dt-sidebar__container">
        {AuthHelper.isAuthorised() && (
          <div className="d-flex flex-column justify-content-between mt-4">
            <ul className="menu-items">
              {menuItems.map((item) => {
                const isHiddenTab = item.onlyAdmin && (
                  !meIsAdmin && !meIsRoot
                );

                if (isHiddenTab) {
                  return null;
                }

                return (
                  <li
                    key={item.linkTo}
                    className="dt-side-nav__item"
                  >
                    <VersionLabel version={item.version || 'prod'}>
                      <Link
                        to={item.linkTo}
                        className={`dt-side-nav__link dt-side-nav__link${currentLocationPathname.includes(
                          item.linkTo.split(
                            '?',
                          )[0],
                        )
                          ? '--active'
                          : ''}`}
                      >
                        <div className="mr-3">
                          <Icon path={item.iconPath} size={1.5} />
                        </div>
                        <p className="dt-side-nav__text">
                          {item.label}
                        </p>
                      </Link>
                    </VersionLabel>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
}

export default SidebarComponent;
