import React, {useContext, useEffect} from 'react';
import { RouterProvider } from 'react-router-dom';
import AuthHelper from './auth/utils';
import Header from './components/Header';
import SidebarComponent from './components/SidebarComponent';
import FooterComponent from './components/Footer';
import { Outlet } from 'react-router-dom';
import _ from "lodash";
import {useSelector} from "react-redux";
import {getEntityDomain, getPartitionsByName} from "./config/selectors";
import {ApiContext} from "./api/ApiContextProvider";
import {getAccess} from "./profile/selectors";

function MainLayout() {
  const entityDomain = useSelector(getEntityDomain);
  const headerPartitions = useSelector(getPartitionsByName);
  const access = useSelector(getAccess);

  const {
    requestGetProfile,
    requestGetPublicInterfaces,
    requestGetPartitions,
  } = useContext(ApiContext);

  useEffect(() => {
    console.log('entityDomain entityDomain', entityDomain)


    if (entityDomain) {
      requestGetPartitions();
    }
  }, [entityDomain]);

  useEffect(() => {
    if (headerPartitions) {
      requestGetProfile()
        .then((response) => AuthHelper.authorizedAccess(_.get(
          response,
          'access',
          false,
        )));
    }
  }, [headerPartitions]);

  useEffect(() => {
    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetPublicInterfaces();
    }
  }, [access]);


  return (
    <div className="dt-root">
      {AuthHelper.isAuthorised() ? <Header /> : null}
      <main className={`${AuthHelper.isAuthorised() ? 'dt-main' : 'mt-0'}`}>
        {AuthHelper.isAuthorised() ? <SidebarComponent /> : null}
        <Outlet />
      </main>
      {AuthHelper.isAuthorised() ? <FooterComponent /> : null}
    </div>
  );
}

export default MainLayout;
