import PropTypes from 'prop-types';
import React from 'react';
import {
  Route, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';

import ErrorBoundary from '../containers/error/ErrorBoundary';
import Login54Origins from '../54origins/components/Login54Origins';
import AuthHelper from '../auth/utils';
import EntityTreePage from '../entityTree/components/EntityTreePage';
import ProfilePage54origins from '../54origins/components/ProfilePage54origins';
import AdminDashboard from '../admin/components/AdminDashboard';
import MainLayout from "../MainLayout";

import { appLabel, configUrlEntity } from '../api/appConfig';


function LoginRouteWrapper() {
  return (
    AuthHelper.isAuthorised() ? <Navigate to="/" /> : <Login54Origins url={configUrlEntity} serviceName={appLabel} reloadJsAfterLoadHTML />
  );
}

function PrivateRouteNext({
  isAdminRoute,
  children,
}) {
  // const userIsAdmin = useSelector(isAdminOrRoot);
  // const authConfigFetching = useSelector(getAuthConfigFetching);

  // if (AuthHelper.isAuthorised() && authConfigFetching) {
  //   return <Loader />;
  // }
  //
  if (!AuthHelper.isAuthorised()) {
    return <Navigate to="/login" replace />;
  }
  //
  // if (isAdminRoute && !userIsAdmin) {
  //   return <Navigate to="/" replace />;
  // }

  return children;
}

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/login" element={<LoginRouteWrapper />} />
        <Route
          path="/"
          element={(
            <PrivateRouteNext>
              <ErrorBoundary>
                <MainLayout />
              </ErrorBoundary>
            </PrivateRouteNext>
)}
        >
          <Route index path="/" element={<Navigate to={{ pathname: '/entity_tree' }} />} />
          <Route path="/profile" element={<ProfilePage54origins />} />
          <Route path="/entity_tree" element={<EntityTreePage />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </>,
    ),
  );

  return (
    <RouterProvider router={router} />
  );
}

export default compose(withCookies)(App);

App.propTypes = {
  cookies: PropTypes.instanceOf(Object).isRequired,
};
