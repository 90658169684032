import {
  Card, Col, Empty, Tabs,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import SearchPermissions from './SearchPermissions';
import ListPermissions from './ListPermissions';
import paramsURL from '../utils/ParamsURL';
import { allListEntityPerm, fetchingGetEntityPerm, getActorsPermsForEntity } from './selectors/selectors';
import { PermissionsContext } from './context';
import { capitalizeFirstChar } from '../utils';
import useURLParams from '../hooks/useURLParams';

const { TabPane } = Tabs;

const hiddenDataViewingListPerm = ['columnEntityName', 'columnDeletePerm'];

const initialSearchData = {
  dataSearchUsers: [],
  dataSearchGroup: [],
};

const initialData = {
  builtInGroup: [],
  otherGroup: [],
  personalPermsInUser: [],
};

const templateUsersWithoutGroup = {
  disabled: true,
  actorName: 'Users without group',
  actor_type: 'group',
  actor_uuid: 'USER_WITHOUT_GROUP',
  entity_uuid: '',
  perm_uuid: '',
  uinfo: { group_name: 'USER_WITHOUT_GROUP', weight: 0 },
  create: false,
  delete: false,
  list: false,
  read: false,
  set: false,
  update: false,
};

function ViewingPermsCard() {
  const { t } = useTranslation();
  const { addAndRemoveSearchParams } = useURLParams();

  const dataFromUrl = useSelector(paramsURL.get);
  const allListPerms = useSelector(allListEntityPerm);
  const entityActorsPermFetching = useSelector(fetchingGetEntityPerm);
  const actorsPermsForEntity = useSelector(getActorsPermsForEntity);

  const {
    checkIsUserType,
  } = useContext(PermissionsContext);

  const [selectedTab, setSelectedTab] = useState('entity');
  const [dataSearch, setDataSearch] = useState(initialSearchData);
  const [listSectionPermissions, setListPerm] = useState(initialData);

  const { activeActorPerm, activeProject } = dataFromUrl;

  const {
    builtInGroup,
    otherGroup,
    personalPermsInUser,
  } = listSectionPermissions;
  const emptySearchData = isEmpty(dataSearch.dataSearchUsers)
  && isEmpty(dataSearch.dataSearchGroup);

  const searchData = (currentSection) => (emptySearchData
    ? false
    : get(dataSearch, currentSection));

  const rowEventsTable = (row) => ({
    onClick: () => {
      addAndRemoveSearchParams({
        activeProject,
        activeActorPerm: row.actor_uuid,
        typeActor: row.actor_type,
      });
    },
  });

  const selectRowTable = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    classes: activeActorPerm && 'row-perm-selected',
    selectedRowKeys: [activeActorPerm],
  };

  const showListPermissions = (dataTable, title) => (
    <ListPermissions
      selectRow={selectRowTable}
      rowEvents={rowEventsTable}
      hiddenData={hiddenDataViewingListPerm}
      dataTable={dataTable}
      cardName="entityCard"
      title={title}
    />
  );

  const checkIsDefaultGroup = (name) => name === 'ADMIN'
  || name === 'BAN'
  || name === 'DEFAULT';

  const filterListPermissions = (perms, currentTab) => {
    const builtInGroupList = [];
    const otherGroupList = [];
    const personalPermsInUserList = [];

    perms.forEach((item) => {
      const {
        actor_type,
        uinfo,
        personal,
      } = item;

      // check and sort default and other group
      if (actor_type === 'group') {
        if (checkIsDefaultGroup(get(uinfo, 'group_name'))) {
          builtInGroupList.push({ ...item, disabled: true });
          return;
        }

        otherGroupList.push({ ...item, disabled: true });
        return;
      }

      // check and sort personalPermsInUsers and otherPermInUsers
      if (checkIsUserType(actor_type) && personal) {
        personalPermsInUserList.push({ ...item, disabled: true });
      }
    });

    setListPerm({
      builtInGroup: currentTab !== 'entity'
        ? [...builtInGroupList, templateUsersWithoutGroup]
        : builtInGroupList,
      otherGroup: otherGroupList,
      personalPermsInUser: personalPermsInUserList,
    });
  };

  const setSelectedTabCallback = (tab) => {
    if (activeActorPerm) {
      paramsURL.clearKey(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
    }
    setSelectedTab(tab);

    const permList = tab === 'entity'
      ? actorsPermsForEntity
      : allListPerms;
    filterListPermissions(permList, tab);
  };

  const listPermissions = (
    <>
      <Col className="d-flex justify-content-between mt-2">
        <SearchPermissions
          setDataSearchCallback={setDataSearch}
        />
      </Col>
      <div className="mt-4">
        { emptySearchData && showListPermissions(
          builtInGroup,
          capitalizeFirstChar(t('permissions.headers.Build_in_groups', 'Build_in_groups')),
        )}

        {showListPermissions(
          searchData('dataSearchGroup') || otherGroup,
          capitalizeFirstChar(t('permissions.headers.Groups', 'Groups')),
        )}

        { showListPermissions(
          searchData('dataSearchUsers') || personalPermsInUser,
          // capitalizeFirstChar(t('wms.permissions.headers.users', 'Users')),
          capitalizeFirstChar(t('permissions.labels.users_with_personal_permissions')),
        )}

        {(
          selectedTab === 'entity'
          && emptySearchData
          && isEmpty([...builtInGroup, ...otherGroup, ...personalPermsInUser])
        ) && (
        <Empty
          description="No permissions set for this entity"
        />
        ) }
      </div>
    </>
  );

  useEffect(() => {
    if (!entityActorsPermFetching) {
      const permList = selectedTab === 'entity'
        ? actorsPermsForEntity
        : allListPerms;

      filterListPermissions(permList, selectedTab);
    }
  }, [entityActorsPermFetching]);

  return (
    <Card className="card border-blue mt-3">
      <h5>{capitalizeFirstChar(t('permissions.labels.entity_permissions'))}</h5>
      <Tabs
        className="mt-3"
        onChange={setSelectedTabCallback}
        type="card"
        activeKey={selectedTab}
      >
        <TabPane tab={capitalizeFirstChar(t('permissions.labels.show_only_set_permissions'))} key="entity">
          {listPermissions}
        </TabPane>
        <TabPane tab={capitalizeFirstChar(t('permissions.labels.show_all_perms'))} key="all">
          {listPermissions}
        </TabPane>
      </Tabs>

    </Card>
  );
}

export default ViewingPermsCard;
